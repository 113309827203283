<template>
  <div class="program">
    <div class="program-header">
      <div class="header-left">
        <el-button
          icon="el-icon-arrow-left"
          size="small"
          @click="goBack()"
          >返回</el-button
        >
        <div class="header-left-name">{{ experimentInfo?.name }}</div>
      </div>

      <div class="header-right"></div>
    </div>
    <div class="program-main" v-loading="loading">
      <div class="left" ref="left">
        <!--        <div style="position: absolute;width: 100%;height: 100%;left: 0;top: 0"></div>-->
        <!--        <embed style="position:relative;z-index: 2" v-if="pdf?.source?.path"  id="iframe" ref="myIframe" :src="pdf?.source?.path" width="100%" height="100%" type="application/pdf" />-->
        <ExperimentSource
          style="width: 100%"
          v-if="sourceGroup.length"
          :sourceGroup="sourceGroup"
        ></ExperimentSource>
        <div v-else style="text-align: center; font-size: 14px">暂无数据</div>
      </div>
      <div class="line" ref="line" @mousedown="md">
        <i class="el-icon-more"></i>
      </div>
      <div class="right" ref="right">
        <div class="right-top">
          <p>{{ link }}</p>
          <el-button
            v-if="experimentInfo.link_as_iframe === '0'"
            type="primary"
            @click="openLink()"
            >打开链接</el-button
          >
          <el-button
            v-if="experimentInfo.link_as_iframe === '1'"
            type="primary"
            @click="openExper()"
            >打开实验</el-button
          >
        </div>
        <div class="right-cont" v-if="experimentInfo.link_as_iframe === '0'">
          <p>请按照以下操作步骤进行实操</p>
          <video
            src="../../assets/images/op_video/experiment_op.mp4"
            autoplay
            muted
            controls
            loop
          ></video>
        </div>
        <iframe
          class="i-frame"
          v-if="experimentInfo.link_as_iframe === '1' && experStatus"
          :src="link"
          frameborder="0"
        ></iframe>
        <div class="i-frame seizeAseat" v-if="experimentInfo.link_as_iframe === '1' && !experStatus">
          <p>点击打开实验按钮开始实验</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as monaco from "monaco-editor";
import ExperimentSource from "./components/experimentSource/index.vue";
export default {
  name: "ProgramView",
  data() {
    return {
      position: {
        x: 0,
        move: false,
      },
      loading: false,
      experimentId: "",
      experimentInfo: {},
      pdf: {},
      link: "",
      rafId: "",
      lastUpdate: 0,
      sourceGroup: [],
      experStatus: false,
    };
  },
  components: {
    ExperimentSource,
  },
  methods: {
    // 拖动中间线条改变两侧大小
    md(e) {
      const { position } = this;
      position.x = e?.target?.offsetLeft || 0;
      position.move = true;
    },
    mv(e) {
      console.log("移动中", this.position.move);
      const { position } = this;
      if (!position.move) return;
      position.x = e.pageX;
      this.$refs.left.style.width = `${e.pageX}px`;
      this.$refs.right.style.width = "0px";
      // this.editor.layout();
    },
    mp() {
      console.log("松开了");
      const { position } = this;
      position.move = false;
    },
    // getLink(){
    //   this.api.course.getLinkAuthUrl({
    //     experiment_id: row.id
    //   }).then(res => {
    //     this.experLinkLoading = false;
    //     // window.open(res.link, "_blank");
    //   });
    // },
    getDetail() {
      let params = {
        id: this.experimentId,
      };
      this.loading = true;
      this.api.dataset
        .experimentDetail(params)
        .then((res) => {
          this.loading = false;
          this.experimentInfo = res.info;
          console.log(this.experimentInfo.link_as_iframe);
          this.sourceGroup = res.source_group;
          let pdf = res.info.resource.filter((item) => {
            return item.source_type == 3;
          });
          this.pdf = pdf.length ? pdf[0] : [];
          let link = res.info.resource.filter((item) => {
            return item.source_type == 7;
          });
          this.link = link.length ? link[0].source.path : "";
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    openLink() {
      window.open(this.link, "_blank");
    },
    openExper() {
      if(this.experStatus) return;
      this.experStatus = true;
    },
    goBack(){
      this.$router.replace({
        path:"/trainingcamp/experiment-code/2"
      });
    }
  },
  created() {
    const { query } = this.$route;
    this.experimentId = query.id || "";
    this.getDetail();
  },
  mounted() {
    window.addEventListener("mousemove", this.mv);
    window.addEventListener("mouseup", this.mp);
  },
  beforeDestroy() {
    console.log("即将销毁");
    window.removeEventListener("mousemove", this.mv);
    window.removeEventListener("mouseup", this.mp);
  },
};
</script>

<style lang="scss" scoped>
.program {
  height: 100vh;
  overflow: hidden;
  background-color: white;

  .program-header {
    height: 60px;
    box-sizing: border-box;
    background-color: white;
    border-bottom: 10px solid #f7f8f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .header-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .header-left-name {
      margin: 0 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .program-main {
    height: calc(100% - 70px);
    display: flex;
    overflow: auto;

    .left {
      width: 40%;
      overflow-y: auto;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
    }

    .line {
      width: 10px;
      transition: 0.3s;
      background-color: #f7f8f9;
      cursor: move;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #686868;

      i {
        transform: rotate(90deg);
      }

      &:hover {
        background-color: #dfdfdf;
      }
    }

    .right {
      padding: 20px;
      flex-grow: 1;
      height: 100px;
      .right-top {
        width: calc(100%);
        padding: 0 20px;
        height: 81px;
        background: #f8f8f9;
        border: 1px solid #eeeeee;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-size: 14px;
      }
      .i-frame {
        width: 100%;
        margin-top: 20px;
        border: 1px solid #ddd;
        height: calc(100vh - 200px);
      }
      .seizeAseat{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .right-cont {
        text-align: center;
        > p {
          margin-top: 100px;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
        > video {
          width: 493px;
          height: 328px;
        }
      }
    }
  }
}
</style>
